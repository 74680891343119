.root
  width: 700px

.form
  display: flex
  flex-direction: column
  min-height: 450px

.formInputHeading
  padding-bottom: 0.5rem

.fullWidthInput
  width: 100%
  margin-bottom: 2rem

.footer
  display: flex
  justify-content: center
  gap: 16px
  width: 100%
  button
    min-width: 120px

.productsList
  display: flex
  align-items: center
  gap: 8px
  flex-wrap: wrap
