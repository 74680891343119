@import "src/theme/constants.scss"

.target
  display: flex
  background-color: $inputMainBgColor
  padding: 8px
  border-radius: 20px
  margin: 1px
  .content
    display: flex
    flex-wrap: wrap
    flex: 1 1 auto
    overflow: hidden
    gap: 4px

    &>*
      max-width: calc(100% - 20px)

.input
  background: none
  border: none
  outline: none
  color: inherit
  pointer-events: none
  line-height: 26px
  padding: 0
  padding-left: 5px

.target:focus-within,
.target[data-active=true]
  box-shadow: 0px 0px 0px 1px $mainText100Color

.target.withError
  box-shadow: 0px 0px 0px 1px $red100Color

.arrowContainer,
.controlContainer
  display: flex
  flex-direction: column
  justify-content: center

.arrowContainer
  padding: 0
  padding-left: 5px
  margin-left: 5px
  border: none
  border-left: 1px solid $dividerLineLightColor
  background: transparent
  color: inherit
  text-align: initial
  outline: none

.controlContainer
  margin-left: 5px
  border: none

.controlIcon
  padding: 5px
  width: 25px

.valueText
  margin-block: 4px
  padding-inline: 5px
  line-height: 18px !important
  width: fit-content
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.notFountText
  padding: 5px
  user-select: none

.dropdownBox
  display: flex
  flex-direction: column
  min-width: 100
  outline: 0
  padding: 0
  overflow: hidden
  border-radius: $medium-border-radius

.listContainer
  display: flex
  flex-direction: column
  gap: 4px
  overflow: auto
  padding-inline: 5px
  margin-block: 5px
  height: 100%
  scrollbar-width: thin
  scrollbar-color: $dividerLineLightColor transparent

.target.disabled,
.dropdownBox.disabled
  cursor: not-allowed
  user-select: none
  pointer-events: none

.target.disabled
  opacity: 0.7
