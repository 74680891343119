@import "src/theme/colors.scss";

.icon,
:global(.icon) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  font-size: 1em;
  z-index: initial;
  transition: transform 0.1s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-primary {
  color: $primary100Color;
}

.color-secondary {
  color: $mainText50Color;
}

.color-default {
  color: $mainText100Color;
}

.color-transparent {
  color: transparent;
}

.color-green {
  color: $green100Color;
}
.color-gold {
  color: $gold100Color;
}
.color-red {
  color: $red100Color;
}
.inline {
  display: inline;
}

.clickable {
  cursor: pointer;
}
.icon-factory:before {
  content: "\ea36";
}
.icon-parcel:before {
  content: "\ea37";
}

.icon-checkbox-blank-circle-fill:before {
  content: "\e900";
}
.icon-checkbox-blank-circle:before {
  content: "\e901";
}
.icon-shield-fill:before {
  content: "\e902";
}
.icon-shield:before {
  content: "\e903";
}
.icon-factory:before {
  content: "\ea36";
}
.icon-parcel:before {
  content: "\ea37";
}
.icon-shield-check-fill:before {
  content: "\ea32";
}
.icon-shield-check:before {
  content: "\ea33";
}
.icon-sparkling-fill:before {
  content: "\ea34";
}
.icon-sparkling:before {
  content: "\ea35";
}
.icon-chat-new-fill:before {
  content: "\ea2b";
}
.icon-chat-new:before {
  content: "\ea2c";
}
.icon-image-add-fill:before {
  content: "\ea2d";
}
.icon-image-add:before {
  content: "\ea2e";
}
.icon-play-list-add:before {
  content: "\ea2f";
}
.icon-chat-delete-fill:before {
  content: "\ea30";
}
.icon-chat-delete:before {
  content: "\ea31";
}
.icon-contrast-drop:before {
  content: "\ea25";
}
.icon-drop-fill:before {
  content: "\ea26";
}
.icon-drop:before {
  content: "\ea27";
}
.icon-blur-off-fill:before {
  content: "\ea28";
}
.icon-blur-off:before {
  content: "\ea29";
}
.icon-book-shelf:before {
  content: "\ea2a";
}
.icon-align-bottom:before {
  content: "\ea13";
}
.icon-align-center:before {
  content: "\ea14";
}
.icon-align-left:before {
  content: "\ea15";
}
.icon-align-right:before {
  content: "\ea16";
}
.icon-align-top:before {
  content: "\ea17";
}
.icon-align-vertically:before {
  content: "\ea18";
}
.icon-bold:before {
  content: "\ea19";
}
.icon-font-color:before {
  content: "\ea1a";
}
.icon-h-1:before {
  content: "\ea1b";
}
.icon-h-2:before {
  content: "\ea1c";
}
.icon-h-3:before {
  content: "\ea1d";
}
.icon-h-4:before {
  content: "\ea1e";
}
.icon-h-5:before {
  content: "\ea1f";
}
.icon-h-6:before {
  content: "\ea20";
}
.icon-italic:before {
  content: "\ea21";
}
.icon-paint-fill:before {
  content: "\ea22";
}
.icon-paint:before {
  content: "\ea23";
}
.icon-strikethrough:before {
  content: "\ea24";
}
.icon-funnel:before {
  content: "\ea0d";
}
.icon-clipboard-fill:before {
  content: "\ea0e";
}
.icon-clipboard:before {
  content: "\ea0f";
}
.icon-dropdown-list:before {
  content: "\ea10";
}
.icon-scissors:before {
  content: "\ea11";
}
.icon-paperclip:before {
  content: "\ea12";
}
.icon-leaf-fill:before {
  content: "\e9f1";
}
.icon-leaf:before {
  content: "\e9f2";
}
.icon-lightbulb-fill:before {
  content: "\e9f3";
}
.icon-lightbulb:before {
  content: "\e9f4";
}
.icon-money-dollar-circle-fill:before {
  content: "\e9f5";
}
.icon-money-dollar-circle:before {
  content: "\e9f6";
}
.icon-presentation-fill:before {
  content: "\e9f7";
}
.icon-presentation:before {
  content: "\e9f8";
}
.icon-slideshow-fill:before {
  content: "\e9f9";
}
.icon-slideshow:before {
  content: "\e9fa";
}
.icon-stock-fill:before {
  content: "\e9fb";
}
.icon-stock:before {
  content: "\e9fc";
}
.icon-survey-fill:before {
  content: "\e9fd";
}
.icon-survey:before {
  content: "\e9fe";
}
.icon-swap-fill:before {
  content: "\e9ff";
}
.icon-swap:before {
  content: "\ea00";
}
.icon-truck-fill:before {
  content: "\ea01";
}
.icon-truck:before {
  content: "\ea02";
}
.icon-water-flash-fill:before {
  content: "\ea03";
}
.icon-water-flash:before {
  content: "\ea04";
}
.icon-aed-fill:before {
  content: "\ea05";
}
.icon-aed:before {
  content: "\ea06";
}
.icon-box-fill:before {
  content: "\ea07";
}
.icon-box:before {
  content: "\ea08";
}
.icon-contacts-book-upload-fill:before {
  content: "\ea09";
}
.icon-contacts-book-upload:before {
  content: "\ea0a";
}
.icon-flask-fill:before {
  content: "\ea0b";
}
.icon-flask:before {
  content: "\ea0c";
}
.icon-recommended:before {
  content: "\e9f0";
}
.icon-AI:before {
  content: "\e9d9";
}
.icon-link:before {
  content: "\e9ec";
}
.icon-plug:before {
  content: "\e9ed";
}
.icon-alert-fill:before {
  content: "\e9ee";
}
.icon-alert:before {
  content: "\e9ef";
}
.icon-recover:before {
  content: "\e9eb";
}
.icon-prohibited:before {
  content: "\e9dd";
}
.icon-pulse:before {
  content: "\e9de";
}
.icon-pushpin-vertical-fill:before {
  content: "\e9df";
}
.icon-pushpin-vertical-line:before {
  content: "\e9e0";
}
.icon-pushpin-fill:before {
  content: "\e9e1";
}
.icon-pushpin:before {
  content: "\e9e2";
}
.icon-unpin-fill:before {
  content: "\e9e3";
}
.icon-unpin:before {
  content: "\e9e4";
}
.icon-draggable:before {
  content: "\e9e5";
}
.icon-file-forbid-fill:before {
  content: "\e9e6";
}
.icon-file-forbid:before {
  content: "\e9e7";
}
.icon-folder-forbid-fill:before {
  content: "\e9e8";
}
.icon-folder-forbid:before {
  content: "\e9e9";
}
.icon-jigsaw-puzzle:before {
  content: "\e9ea";
}
.icon-hard-drive:before {
  content: "\e9c9";
}
.icon-palette-fill:before {
  content: "\e9ca";
}
.icon-palette:before {
  content: "\e9cb";
}
.icon-pantone-fill:before {
  content: "\e9cc";
}
.icon-pantone:before {
  content: "\e9cd";
}
.icon-hard-drive-fill:before {
  content: "\e9ce";
}
.icon-maintenance-manual:before {
  content: "\e9cf";
}
.icon-handbook-fill:before {
  content: "\e9d0";
}
.icon-storage:before {
  content: "\e9d1";
}
.icon-safety-data-sheet:before {
  content: "\e9d2";
}
.icon-sand-time:before {
  content: "\e9d3";
}
.icon-standards:before {
  content: "\e9d4";
}
.icon-data-sheets:before {
  content: "\e9d5";
}
.icon-thunderbolt:before {
  content: "\e9d6";
}
.icon-time:before {
  content: "\e9d7";
}
.icon-voice:before {
  content: "\e9d8";
}
.icon-competitor-intelligence:before {
  content: "\e9da";
}
.icon-engineering-design:before {
  content: "\e9db";
}
.icon-file:before {
  content: "\e9dc";
}
.icon-file-chart-2-fill:before {
  content: "\e9b2";
}
.icon-file-chart-2:before {
  content: "\e9b3";
}
.icon-file-chart-fill:before {
  content: "\e9b4";
}
.icon-file-chart:before {
  content: "\e9b5";
}
.icon-flow-chart:before {
  content: "\e9b6";
}
.icon-folder-chart-2:before {
  content: "\e9b7";
}
.icon-folder-chart:before {
  content: "\e9b8";
}
.icon-funds-box-fill:before {
  content: "\e9b9";
}
.icon-funds-box:before {
  content: "\e9ba";
}
.icon-funds-fill:before {
  content: "\e9bb";
}
.icon-funds:before {
  content: "\e9bc";
}
.icon-line-chart:before {
  content: "\e9bd";
}
.icon-line-chart1:before {
  content: "\e9be";
}
.icon-openai-fill:before {
  content: "\e9bf";
}
.icon-organization-chart:before {
  content: "\e9c0";
}
.icon-pie-chart-2-fill:before {
  content: "\e9c1";
}
.icon-pie-chart-2:before {
  content: "\e9c2";
}
.icon-pie-chart-box-fill:before {
  content: "\e9c3";
}
.icon-pie-chart-box:before {
  content: "\e9c4";
}
.icon-pie-chart-fill:before {
  content: "\e9c5";
}
.icon-pie-chart:before {
  content: "\e9c6";
}
.icon-rhythm:before {
  content: "\e9c7";
}
.icon-voiceprint:before {
  content: "\e9c8";
}
.icon-user-insight:before {
  content: "\e9b1";
}
.icon-glossary:before {
  content: "\e9a8";
}
.icon-mic-fill:before {
  content: "\e9a9";
}
.icon-mic:before {
  content: "\e9aa";
}
.icon-mic-off-fill:before {
  content: "\e9ab";
}
.icon-mic-off:before {
  content: "\e9ac";
}
.icon-record-circle-fill:before {
  content: "\e9ad";
}
.icon-record-circle:before {
  content: "\e9ae";
}
.icon-voice-recognition-fill:before {
  content: "\e9af";
}
.icon-voice-recognition:before {
  content: "\e9b0";
}
.icon-calendar-event-fill:before {
  content: "\e993";
}
.icon-calendar-event-line:before {
  content: "\e994";
}
.icon-checkbox-blank-fill:before {
  content: "\e995";
}
.icon-checkbox-blank-line:before {
  content: "\e996";
}
.icon-checkbox-circle-fill:before {
  content: "\e997";
}
.icon-checkbox-circle-line:before {
  content: "\e998";
}
.icon-checkbox-fill:before {
  content: "\e999";
}
.icon-checkbox-indeterminate-fill:before {
  content: "\e99a";
}
.icon-checkbox-indeterminate-line:before {
  content: "\e99b";
}
.icon-checkbox-line:before {
  content: "\e99c";
}
.icon-collage-fill:before {
  content: "\e99d";
}
.icon-collage-line:before {
  content: "\e99e";
}
.icon-flag-fill:before {
  content: "\e99f";
}
.icon-flag-line:before {
  content: "\e9a0";
}
.icon-instance-fill:before {
  content: "\e9a1";
}
.icon-instance-line:before {
  content: "\e9a2";
}
.icon-list-check-3:before {
  content: "\e9a3";
}
.icon-list-indefinite:before {
  content: "\e9a4";
}
.icon-node-tree:before {
  content: "\e9a5";
}
.icon-terminal-window-fill:before {
  content: "\e9a6";
}
.icon-terminal-window-line:before {
  content: "\e9a7";
}
.icon-watch-fill:before {
  content: "\e98b";
}
.icon-watch:before {
  content: "\e98c";
}
.icon-timer-fill:before {
  content: "\e98d";
}
.icon-timer:before {
  content: "\e98e";
}
.icon-group-fill:before {
  content: "\e98f";
}
.icon-group:before {
  content: "\e990";
}
.icon-map-pin-fill:before {
  content: "\e991";
}
.icon-map-pin:before {
  content: "\e992";
}
.icon-folder-shield-2:before {
  content: "\e978";
}
.icon-folder-user-fill:before {
  content: "\e979";
}
.icon-folder-user:before {
  content: "\e97a";
}
.icon-key-2-fill:before {
  content: "\e97b";
}
.icon-key-2:before {
  content: "\e97c";
}
.icon-p2p-fill:before {
  content: "\e97d";
}
.icon-p2p:before {
  content: "\e97e";
}
.icon-shield-keyhole-fill:before {
  content: "\e97f";
}
.icon-shield-keyhole:before {
  content: "\e980";
}
.icon-shield-user-fill:before {
  content: "\e981";
}
.icon-shield-user:before {
  content: "\e982";
}
.icon-user-settings-fill:before {
  content: "\e983";
}
.icon-user-settings:before {
  content: "\e984";
}
.icon-account-circle-fill:before {
  content: "\e985";
}
.icon-account-circle:before {
  content: "\e986";
}
.icon-folder-lock-fill:before {
  content: "\e987";
}
.icon-folder-lock:before {
  content: "\e988";
}
.icon-folder-shield-2-fill:before {
  content: "\e989";
}
.icon-eject:before {
  content: "\e964";
}
.icon-eye-close-fill:before {
  content: "\e965";
}
.icon-eye-close:before {
  content: "\e966";
}
.icon-line-height:before {
  content: "\e967";
}
.icon-picture-in-picture-fill:before {
  content: "\e968";
}
.icon-picture-in-picture:before {
  content: "\e969";
}
.icon-picture-in-picture-exit-fill:before {
  content: "\e96a";
}
.icon-picture-in-picture-exit:before {
  content: "\e96b";
}
.icon-upload-fill:before {
  content: "\e96c";
}
.icon-upload:before {
  content: "\e96d";
}
.icon-align-justify:before {
  content: "\e96e";
}
.icon-arrow-down-fill:before {
  content: "\e96f";
}
.icon-arrow-down1:before {
  content: "\e970";
}
.icon-arrow-up-fill:before {
  content: "\e971";
}
.icon-arrow-up:before {
  content: "\e972";
}
.icon-aspect-ratio-fill:before {
  content: "\e973";
}
.icon-aspect-ratio:before {
  content: "\e974";
}
.icon-download-fill:before {
  content: "\e975";
}
.icon-download:before {
  content: "\e976";
}
.icon-eject-fill:before {
  content: "\e977";
}
.icon-bar-chart-horizontal:before {
  content: "\e954";
}
.icon-checkbox-multiple-blank-fill:before {
  content: "\e955";
}
.icon-checkbox-multiple-blank:before {
  content: "\e956";
}
.icon-grid-fill:before {
  content: "\e957";
}
.icon-grid:before {
  content: "\e958";
}
.icon-layout-grid-fill:before {
  content: "\e959";
}
.icon-layout-grid:before {
  content: "\e95a";
}
.icon-layout-masonry-fill:before {
  content: "\e95b";
}
.icon-layout-masonry:before {
  content: "\e95c";
}
.icon-profile-fill:before {
  content: "\e95d";
}
.icon-profile:before {
  content: "\e95e";
}
.icon-apps-2-fill:before {
  content: "\e95f";
}
.icon-apps:before {
  content: "\e960";
}
.icon-bar-chart-box-fill:before {
  content: "\e961";
}
.icon-bar-chart-box:before {
  content: "\e962";
}
.icon-bar-chart-horizontal-fill:before {
  content: "\e963";
}
.icon-rocket:before {
  content: "\e94b";
}
.icon-user-add-fill:before {
  content: "\e94d";
}
.icon-user-add:before {
  content: "\e94e";
}
.icon-user-follow-fill:before {
  content: "\e94f";
}
.icon-user-follow:before {
  content: "\e950";
}
.icon-user-unfollow-fill:before {
  content: "\e951";
}
.icon-user-unfollow:before {
  content: "\e952";
}
.icon-bubble-chart-fill:before {
  content: "\e943";
}
.icon-bubble-chart:before {
  content: "\e944";
}
.icon-lock-fill:before {
  content: "\e945";
}
.icon-lock:before {
  content: "\e946";
}
.icon-lock-unlock-fill:before {
  content: "\e947";
}
.icon-lock-unlock:before {
  content: "\e948";
}
.icon-user-fill:before {
  content: "\e949";
}
.icon-user:before {
  content: "\e94a";
}
.icon-workspaces:before {
  content: "\e942";
}
.icon-bell:before {
  content: "\e93d";
}
.icon-trending-down:before {
  content: "\e93e";
}
.icon-trending-up:before {
  content: "\e93f";
}
.icon-share-email:before {
  content: "\e940";
}
.icon-slack:before {
  content: "\e941";
}
.icon-format_list_numbered:before {
  content: "\e93b";
}
.icon-format_list_bulleted:before {
  content: "\e93c";
}
.icon-questions-chat:before {
  content: "\e93a";
}
.icon-cloud:before {
  content: "\e931";
}
.icon-external-link:before {
  content: "\e932";
}
.icon-maximize:before {
  content: "\e933";
}
.icon-minimize:before {
  content: "\e934";
}
.icon-minus:before {
  content: "\e935";
}
.icon-plus:before {
  content: "\e936";
}
.icon-cross:before {
  content: "\e937";
}
.icon-zoom-in:before {
  content: "\e938";
}
.icon-zoom-out:before {
  content: "\e939";
}
.icon-stats-straight:before {
  content: "\e904";
}
.icon-stats-random:before {
  content: "\e91c";
}
.icon-twi:before {
  content: "\e92a";
}
.icon-eye:before {
  content: "\e92b";
}
.icon-fb:before {
  content: "\e92c";
}
.icon-phone:before {
  content: "\e92d";
}
.icon-insta:before {
  content: "\e92e";
}
.icon-camera:before {
  content: "\e92f";
}
.icon-cloud-filled:before {
  content: "\e925";
}
.icon-folder:before {
  content: "\e926";
}
.icon-help:before {
  content: "\e927";
}
.icon-info:before {
  content: "\e928";
}
.icon-retry:before {
  content: "\e929";
}
.icon-edit:before {
  content: "\e921";
}
.icon-calendar:before {
  content: "\e922";
}
.icon-landscape:before {
  content: "\e915";
}
.icon-grid-layout:before {
  content: "\e916";
}
.icon-lamp:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e918";
}
.icon-line-arrow-down:before {
  content: "\e98a";
}
.icon-big-arrow-up:before {
  content: "\e919";
}
.icon-big-arrow-up-filled:before {
  content: "\e930";
}
.icon-bookmark:before {
  content: "\e91a";
}
.icon-bookmark-filled:before {
  content: "\e91b";
}
.icon-done:before {
  content: "\e91d";
}
.icon-dots:before {
  content: "\e91e";
}
.icon-global:before {
  content: "\e91f";
}
.icon-home:before {
  content: "\e920";
}
.icon-fat-done:before {
  content: "\e912";
}
.icon-trash-can:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e914";
}
.icon-close-filled:before {
  content: "\e923";
}
.icon-loader:before {
  content: "\e905";
}
.icon-log-out:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e907";
}
.icon-question-mark:before {
  content: "\e908";
}
.icon-question-mark-filled:before {
  content: "\e924";
}
.icon-search:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e90a";
}
.icon-share:before {
  content: "\e90b";
}
.icon-sliders:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
.icon-star-filled:before {
  content: "\e90e";
}
.icon-thumbs-up:before {
  content: "\e90f";
}
.icon-thumbs-down:before {
  content: "\e910";
}
.icon-thumbs-up-filled:before {
  content: "\e911";
}
.icon-thumbs-down-filled:before {
  content: "\e94c";
}
.icon-top-down-arrows:before {
  content: "\e953";
}
.icon-upload-cloud:before {
  content: "\ea38";
}
.icon-users:before {
  content: "\ea39";
}
.icon-laughing-face:before {
  content: "\ea3a";
}
.icon-sad-face:before {
  content: "\ea3b";
}