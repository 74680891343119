@import 'src/theme/colors.scss'

.root
  display: inline-flex
  align-items: stretch
  width: fit-content

.root.sortable
  margin-right: -28px

.clickable
  user-select: none
  cursor: pointer

.content
  overflow: hidden
  display: inline-block

.arrow
  padding: 5px 8px
  display: inline-block
