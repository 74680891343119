@import 'src/theme/constants.scss'
@import 'src/theme/mixins.sass'

.root
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  height: 100vh
  height: 100dvh

.header
  height: 70px
  padding-inline: 30px
  border-bottom: 1px solid $dividerLineLightColor

.body
  display: flex
  flex: 1 1 100%
  overflow: auto
  justify-content: center
  @include thinScroll('&', 2px)

.backButtonWrapper
  display: flex
  align-items: center
  gap: 8px

@media (max-width: $tablet-max-height)
  .root
    height: calc(100vh - 72px)
    height: calc(100dvh - 72px)

  .header
    padding: 0 16px 16px
    border-bottom: 0
    position: sticky
    top: 0

@media (max-width: $tablet-min-height)
  .newChatButton
    padding: 0
    span
      margin-right: 0
