@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 16px
  width: 100%

.questions
  display: grid
  gap: 16px
  width: 100%
  max-width: 888px
  justify-content: center
  align-self: center
  grid-template-columns: repeat(2, 1fr)

.question
  width: 100%
  display: flex
  justify-content: left
  overflow: hidden
  text-overflow: ellipsis
  padding: 8px
  color: $mainText70Color
  border-color: $primary80Color
  border-radius: 8px
  background-color: $primaryContrast10OpacityColor
  padding: 12px 24px
  cursor: pointer

.questionOverflow
  display: block
  overflow: hidden
  text-overflow: ellipsis
  width: 100%

.tooltipContainer
  width: 100%

.questionHeader
  max-width: 888px
  justify-content: center
  align-self: center
  width: 100%

@media (max-width: $tablet-max-height)
  .root
    width: calc(100% - 32px)
  .questions
    width: 100%

@media (max-width: $mobile-min-width)
  .root
    width: calc(100% - 40px)
  .questions
    display: flex
    overflow-x: scroll
    width: fit-content
  .questionOverflow
    display: block
    white-space: wrap
    overflow: hidden
    width: 100%
  .question
    width: 160px
    padding: 8px
  .questionsWrapper
    width: 100%
    overflow: auto
    scrollbar-width: none
    -ms-overflow-style: none
    &::-webkit-scrollbar
      width: 0px

@media(min-width: $tablet-max-width)
  .down
    width: 65%
    max-width: 65%

@media(min-width: $container-max-width)
  .root
    padding-left: 12px
